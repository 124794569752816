var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.chartLoading),expression:"chartLoading"}],staticClass:"pd-x-5"},[_c('div',[_c('h3',[_vm._v("ภาพรวมในแต่ละด้าน")]),_c('div',[_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('div',[_c('h4',{staticClass:"mg-b-0"},[_vm._v("ขนาดธุรกิจ")]),(!_vm.chartLoading)?_c('div',[_c('barChart',{attrs:{"label":['Small', 'Medium', 'Large'],"height":'270px',"data":_vm.businessSize}})],1):_vm._e()])]),_c('el-col',{attrs:{"span":12}},[_c('h4',{staticClass:"mg-b-0"},[_vm._v("ลักษณะธุรกิจ")]),(!_vm.chartLoading)?_c('div',[_c('barChart',{attrs:{"label":[
                'ธุรกิจบริการ',
                'ธุรกิจอุตสาหกรรม\n หรือ \nธุรกิจประเภทผลิต',
                'ธุรกิจพาณิชยกรรม\n หรือ \nธุรกิจแบบซื้อมาขายไป',
              ],"height":'300px',"data":_vm.businessType}})],1):_vm._e()])],1)],1)]),_c('div',[_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('h4',{staticClass:"mg-b-0"},[_vm._v("รายได้ แบ่งตามประเภทธุรกิจ")]),(!_vm.chartLoading)?_c('div',[_c('barChart',{attrs:{"label":[
              'ธุรกิจบริการ',
              'ธุรกิจอุตสาหกรรม\n หรือ \nธุรกิจประเภทผลิต',
              'ธุรกิจพาณิชยกรรม\n หรือ \nธุรกิจแบบซื้อมาขายไป',
            ],"height":'300px',"data":_vm.revenueBybusinessType,"legend":_vm.legendRevenue}})],1):_vm._e()]),_c('el-col',{attrs:{"span":12}},[_c('h4',{staticClass:"mg-b-0"},[_vm._v("สัดส่วนในการส่งออก")]),(!_vm.chartLoading)?_c('div',[_c('PieChart',{attrs:{"data":_vm.exportProportion}})],1):_vm._e()])],1)],1),_c('div',[_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('h4',{staticClass:"mg-b-0"},[_vm._v(" ความสม่ำเสมอของการสั่งซื้อจากผู้ซื้อต่างประเทศรายเดิม ")]),(!_vm.chartLoading)?_c('div',[_c('barChart',{attrs:{"label":[
              'สม่ำเสมอ \nเป็นระยะเวลา \n1-2 ปี',
              'เป็นครั้งคราวเป็น\nระยะเวลา \n1-2 ปี',
              'สม่ำเสมอ\nเป็นระยะเวลา\nมากกว่า 2 ปี',
              'เป็นครั้งคราว\nเป็นระยะเวลา\nมากกว่า 2 ปี',
            ],"height":'300px',"data":_vm.orderConsistency}})],1):_vm._e()]),_c('el-col',{attrs:{"span":12}},[_c('h4',{staticClass:"mg-b-0"},[_vm._v("ความถี่ในการส่งออกโดยเฉลี่ย")]),(!_vm.chartLoading)?_c('div',[_c('barChart',{attrs:{"label":[
              'มากกว่า\n 1 ครั้งต่อ\nเดือน',
              'มากกว่า\n 1 ครั้งต่อ\nไตรมาส',
              'มากกว่า\n 1 ครั้งต่อ\n 6 เดือน',
              'มากกว่า\n 1 ครั้ง\nต่อปี',
            ],"height":'300px',"data":_vm.exportFrequency}})],1):_vm._e()])],1)],1),_c('div',[_c('el-row',[_c('el-col',{attrs:{"span":18}},[_c('h4',{staticClass:"mg-b-0"},[_vm._v("จำนวนประเทศที่ทำการส่งออก")]),(!_vm.chartLoading)?_c('div',[_c('BarYChart',{attrs:{"label":['มากกว่า 5 ประเทศ', '3-5 ประเทศ', '1-2 ประเทศ'],"height":'400px',"data":_vm.country}})],1):_vm._e()])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }